<!-- System: STA
    Purpose: In dashboard widgets user can see his
          projects, time worked and employee card.
-->
<template>
  <div class="widgets mt-10">
    <!-- Employee Card  -->
    <v-card
      class="mr-5 mb-2 dashboard-boxes"
      title="Employees"
      elevation="15"
      v-if="
        selectedCompanyUserStatus !== 'employee' &&
        widget_data.members_count &&
        showPermission
      "
    >
      <v-card-title class="dashboard-boxes-titles">Employees</v-card-title>
      <div class="img-div text-center">
        <img src="@/assets/images/dashboard_icons/Employee Status SVG.svg" />
      </div>
      <div class="row status">
        <div class="col-md-6 text-center active-status">
          <div class="number" v-if="widget_data.active_members">
            {{ widget_data.active_members }}
          </div>
          <div class="number" v-else>0</div>
          <div style="margin-left: -14px" class="mt-5">
            <v-icon class="mr-2" x-small color="#4acf8b"
              >mdi-checkbox-blank-circle</v-icon
            >
            <span class="time-text">Worked</span>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <div class="number" v-if="widget_data.inactive_members">
            {{ widget_data.inactive_members }}
          </div>
          <div class="number" v-else>0</div>
          <div style="margin-left: -11px" class="mt-5">
            <v-icon class="mr-2" x-small color="#ff0000"
              >mdi-checkbox-blank-circle</v-icon
            >
            <span class="time-text">Absent</span>
          </div>
        </div>
      </div>
    </v-card>
    <!-- Projects Card  -->
    <v-card class="mr-5 mb-2 dashboard-boxes" elevation="15">
      <v-card-title class="dashboard-boxes-titles">Projects</v-card-title>
      <div
        v-show="widget_data.project_count"
        class="counts float-left col-sm-8"
      >
        {{ widget_data.project_count }}
      </div>
      <div class="img-div text-center">
        <img src="../../assets/images/dashboard_icons/project_stats.svg" />
      </div>
      <div class="row status">
        <div class="col-md-12 text-center">
          <div
            class="number"
            v-if="selectedCompanyUserStatus !== 'employee' && showPermission"
          >
            {{ widget_data.current_projects }}
          </div>
          <div class="number" v-else="selectedCompanyUserStatus === 'employee'">
            {{ widget_data.current_projects }}
          </div>
          <div style="margin-left: -14px" class="mt-5">
            <v-icon class="mr-2" x-small color="#780ae5"
              >mdi-checkbox-blank-circle</v-icon
            >
            <span class="time-text">Active</span>
          </div>
        </div>
      </div>
    </v-card>
    <!-- Time Worked Card  -->
    <v-card class="dashboard-boxes mb-2" elevation="15">
      <v-card-title class="dashboard-boxes-titles">Time Worked</v-card-title>
      <div class="counts">{{ widget_data.member_count }}</div>
      <div class="img-div text-center">
        <img src="../../assets/images/dashboard_icons/dashboard_clock.svg" />
      </div>
      <div class="col-sm-12 m-0 p-0 text-center">
        <div class="counts mt-1 time-value" title="Hours:Minutes">
          <span class="mr-1" v-if="widget_data.total_minutes.hrs <= 9">
            0{{ widget_data.total_minutes.hrs }}
          </span>
          <span class="mr-1" v-else>
            {{ widget_data.total_minutes.hrs }}
          </span>
          <span class="font-weight-normal mr-1">:</span>
          <span v-if="widget_data.total_minutes.mins <= 9"
            >0{{ widget_data.total_minutes.mins }}</span
          >
          <span v-else>
            {{ widget_data.total_minutes.mins }}
          </span>
        </div>
        <div class="counts mt-0 time-text" data-v-a8321274="" title="">
          <span style="margin-left: 8px">Hrs : Mins</span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "DashboardWidgets",
  props: ["widget_data", "start", "end", "last_working_day"],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("custom", [
      "selected_company",
      "team_members",
      "all_store_socket_employee",
      "companies_list",
    ]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    /**
     * Return the permission status
     * of that user
     */
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.dashboard) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
.dashboard-boxes {
  height: 296px;
  width: 32%;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 22px 40px -25px rgba(55, 72, 154, 0.45) !important;
}
.dashboard-boxes-titles {
  display: flow-root;
  font-size: 24px;
  font-weight: 500;
}
/*.card-div {*/
/*  margin: 15px;*/
/*  height: 253px;*/
/*  width: 315px;*/
/*  border-radius: 3px;*/
/*  background-color: #ffffff;*/
/*  box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.42);*/
/*  border: unset;*/
/*}*/

/*.card-div .card-title {*/
/*  color: #36454f;*/
/*  font-family: "Poppins", "sans-serif";*/
/*  font-size: 18px;*/
/*  font-weight: 500;*/
/*  line-height: 25px;*/
/*  text-align: center;*/
/*  margin-bottom: 20px;*/
/*}*/

.img-div img {
  height: 90px;
  width: 90px;
}

.status {
  height: 65px;
  margin-top: 8px;
  color: #36454f;
  font-size: 12px;
  display: flex;
  flex-wrap: nowrap;
}

.number {
  height: 26px;
  color: #36454f;
  text-align: center;
  font-size: 40px;
  line-height: 51px;
  font-weight: 500;
}

.widgets {
  display: flex;
  justify-content: left;
  width: 92%;
  flex-wrap: wrap;
}

.h5 {
  color: #36454f;
  font-family: "Poppins", "sans-serif";
  font-size: 16px;
  line-height: 25px;
  padding-left: 18px;
}

/*.active-status::after {*/
/*  content: "";*/
/*  border-right: 1px solid #d6e7ff;*/
/*  padding: 22px 0px;*/
/*  right: 4px;*/
/*  top: 12px;*/
/*  position: absolute;*/
/*}*/

.time-value {
  color: #36454f;
  font-size: 40px;
  line-height: 51px;
  font-weight: 500;
}

.time-text {
  font-size: 22px;
  line-height: 33px;
  font-weight: 500;
  color: #36454f;
  /*margin-top: -10px !important;*/
}

.btn-invite-text {
  height: 20px;
  color: #ffffff;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 21px;
  margin-top: 6px;
  text-align: center;
}

.btn-invite {
  height: 36px;
  width: 164px;
  border-radius: 3px;
  background-color: #2758f6;
  box-shadow: 0 2px 4px 0 rgba(175, 175, 175, 0.5);
}

.p-set {
  height: 23px;
  color: #88a1ca;
  font-family: "Poppins";
  font-size: 16px;
  text-align: center;
  margin-bottom: 13%;
}

@media (max-width: 1024px) {
  #page-dashboard .card-div[data-v-a8321274] {
    width: 264px !important;
  }
  .dashboard-boxes {
    width: 392px !important;
  }
}
@media (max-width: 767px) {
  #page-dashboard .card-div[data-v-a8321274][data-v-a8321274] {
    width: 314px !important;
  }
  .single-project[data-v-405a0fa9] {
    max-width: 344px;
  }
}
@media (max-width: 601px) {
  .single-project[data-v-405a0fa9] {
    max-width: 100% !important;
  }
  #page-dashboard .card-div[data-v-a8321274][data-v-a8321274] {
    width: 100% !important;
  }
}
</style>
