import { render, staticRenderFns } from "./DashboardWidgets.vue?vue&type=template&id=4618944d&scoped=true"
import script from "./DashboardWidgets.vue?vue&type=script&lang=js"
export * from "./DashboardWidgets.vue?vue&type=script&lang=js"
import style0 from "./DashboardWidgets.vue?vue&type=style&index=0&id=4618944d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4618944d",
  null
  
)

export default component.exports